// extracted by mini-css-extract-plugin
export var wrap = "facilities-module--wrap--2CH0A";
export var row = "facilities-module--row--3nzH_";
export var item = "facilities-module--item--iykA8";
export var itemImage = "facilities-module--itemImage--2Zq5m";
export var alphabethSection = "facilities-module--alphabethSection--1xSkw";
export var modalText = "facilities-module--modalText--1pPhF";
export var address = "facilities-module--address--DzVYI";
export var phone = "facilities-module--phone--1iW8k";
export var website = "facilities-module--website--Z16oE";
export var closeBtn = "facilities-module--closeBtn--13NMv";